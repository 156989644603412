<template>
  <div class="performance-table">
    <dashboard-table
      key="campaign-dashboard-table"
      :campaigns-data="campaignsData"
      :loading="loadingCampaigns"
      :skeleton-rows="skeletonRows"
      @campaign-click="handleCampaignClick"
      @handle-sort-change="fetchReportingCampaigns">
      <slot
        slot-scope="{ campaigns, loading, graphAxis, graphData }"
        :loading="loading"
        :campaigns="campaigns"
        :graph-axis="graphAxis"
        :graph-data="graphData" />
    </dashboard-table>
    <portal v-if="showStickyHeader" to="app-header-subcontent">
      <div id="performance-table-sink" ref="sink" />
    </portal>
  </div>
</template>

<script>
import AppButton from '@/components/AppButton';
import BarChart from '@/components/charts/BarChart';
import SkeletonBox from '@/components/skeletons/SkeletonBox';
import DashboardTable from '@/components/tables/DashboardTable';
const appHeaderHeight = 110;

export default {
  name: 'PerformanceTable',
  components: { AppButton, SkeletonBox, BarChart, DashboardTable },
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    skeletonRows: {
      type: Number,
      default: 4
    }
  },
  data() {
    return {
      showStickyHeader: true,
      campaignsData: [],
      loadingCampaigns: true,
      loadingCsvFile: false
    };
  },
  computed: {
    orgId() {
      return this.$store.getters.organization.id;
    },
    noCsvData() {
      return this.campaignsData.length === 0;
    },
    dateOptions() {
      return this.$store.getters.getMetricDateOptions;
    },
    graphAxis() {
      let max = 0;
      this.preparedCampaignData.forEach(campaign => {
        if (campaign.hasOwnProperty('performance')) {
          max = Math.max(max, ...campaign.performance);
        }
      });
      return [0, max];
    },
    graphData() {
      let labels = null;
      return this.preparedCampaignData.map(campaign => {
        if (!campaign.hasOwnProperty('performance')) {
          return {};
        }
        if (!labels) labels = new Array(campaign.performance.length).fill(0).map((v, i) => i);
        return {
          labels,
          values: campaign.performance
        };
      });
    },
    isA1() {
      return this.$store.getters.userSubType === 'a1';
    },
    isA2() {
      return this.$store.getters.userSubType === 'a2';
    },
    preparedCampaignData() {
      return !this.loading ? this.campaignsData : new Array(this.skeletonRows).fill(Object.assign({}, new Array(6)));
    }
  },
  watch: {
    dateOptions() {
      this.fetchReportingCampaigns();
    },
    showStickyHeader: {
      immediate: true,
      handler() {
        this.$nextTick(() => this.stickyHeader());
      }
    }
  },
  created() {
    this.fetchReportingCampaigns();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll, this.$supportsPassive && { passive: true });
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, this.$supportsPassive && { passive: true });
    this.handleScroll();
  },
  methods: {
    handleScroll() {
      try {
        this.showStickyHeader = this.$refs.header.getBoundingClientRect().top <= appHeaderHeight;
      } catch (e) {
        this.showStickyHeader = false;
      }
    },
    fetchReportingCampaigns() {
      this.loadingCampaigns = true;
      this.$api.campaigns
        .reporting({
          orgId: this.orgId,
          params: {
            page: this.currentPage,
            ...this.dateOptions
          },
          storeAction: 'fetchReportingCampaigns'
        })
        .then(response => {
          this.campaignsData = Object.freeze(response.data);
          this.loadingCampaigns = false;
          this.$emit('campaigns-data-change', response.data);
        });
    },
    handleClickExportCsv() {
      this.loadingCsvFile = true;
      this.$store
        .dispatch('downloadReportingCampaignsCsv', {
          orgId: this.orgId,
          options: {
            page: this.currentPage,
            ...this.dateOptions,
            format: 'csv'
          }
        })
        .then(() => {
          this.loadingCsvFile = false;
        });
    },
    handleCampaignClick(campaignId) {
      if (!campaignId) {
        return;
      }

      const { action, route, items } = {
        action: 'fetchReportingCampaigns',
        route: `/campaign/${campaignId}/marketing`,
        items: this.$store.getters.reportingCampaignsIds
      };

      this.$store.dispatch('setNavigationStack', {
        apiCall: this.$api.campaigns.reporting,
        name: 'reporting',
        payload: {
          orgId: this.orgId,
          params: {
            page: this.currentPage,
            ...this.dateOptions
          },
          storeAction: action
        },
        totalPages: 0,
        items
      });

      this.$deviceType === 'mobile'
        ? this.$f7router.navigate(route)
        : this.$router.push(route);

      scrollTo(0, 0);
    },
    stickyHeader() {
      if (!this.$refs || !this.$refs.header || !this.showStickyHeader) return;

      const ref = this.$refs.sink;
      const elm = this.$refs.header;

      if (ref) {
        // Remove existing children
        while (ref.firstChild) {
          ref.removeChild(ref.firstChild);
        }

        // Append header
        if (elm) {
          const clone = elm.cloneNode(true);
          ref.appendChild(clone);
        }
      }
    }
  }
};
</script>
