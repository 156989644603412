<template>
  <div class="dashboard-table">
    <div ref="header" class="dashboard-table__header dashboard-table-row">
      <div class="dashboard-table-row__cell dashboard-table-row__cell--name">
        Name
      </div>
      <div class="dashboard-table-row__cell dashboard-table-row__cell--clicks hidden-xs hidden-sm">
        Clicks
      </div>
      <div class="dashboard-table-row__cell dashboard-table-row__cell--sales">
        Sales
      </div>
      <div :class="{ 'hidden-xs': isA2 }" class="dashboard-table-row__cell dashboard-table-row__cell--conv-rate">
        Conv. Rate
      </div>
      <div :class="[`dashboard-table-row__cell-${getColumnType.toLowerCase()}`,{'hidden-xs': isA1}]" class="dashboard-table-row__cell">
        {{ getColumnType }}
      </div>
      <div class="dashboard-table-row__cell dashboard-table-row__cell--chart">
        Sales Trend
      </div>
    </div>
    <div v-if="!loading && !preparedCampaignData.length" class="dashboard-table-row dashboard-table-row--empty">
      <div class="dashboard-table-row__wrapper">
        No Live Campaigns in Range
      </div>
    </div>
    <slot
      v-else
      :campaigns="preparedCampaignData"
      :loading="loading"
      :graph-axis="graphAxis"
      :graph-data="graphData" />
    <portal v-if="showStickyHeader" to="app-header-subcontent">
      <div ref="sink" class="dashboard-table" />
    </portal>
  </div>
</template>

<script>
// NOTE: DEPRECATED use Hoc version in sections directory
import BarChart from '@/components/charts/BarChart';
import SkeletonBox from '@/components/skeletons/SkeletonBox';

export default {
  name: 'DashboardTable',
  components: { SkeletonBox, BarChart },
  props: {
    campaignsData: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    skeletonRows: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      appHeaderHeight: 60,
      showStickyHeader: true
    };
  },
  computed: {
    graphAxis() {
      let max = 10;
      this.preparedCampaignData.forEach(campaign => {
        if (campaign.hasOwnProperty('performance')) {
          max = Math.max(max, ...campaign.performance);
        }
      });
      return [0, max];
    },
    graphData() {
      let labels = null;
      return this.preparedCampaignData.map(campaign => {
        if (!campaign.hasOwnProperty('performance')) {
          return {};
        }
        if (!labels) labels = new Array(campaign.performance.length).fill(0).map((v, i) => i);
        return {
          labels,
          values: campaign.performance
        };
      });
    },
    isA1() {
      return this.$store.getters.userSubType === 'a1';
    },
    isA2() {
      return this.$store.getters.userSubType === 'a2';
    },
    preparedCampaignData() {
      return !this.loading ? this.campaignsData : new Array(this.skeletonRows).fill(Object.assign({}, new Array(6)));
    },
    orgType() {
      return this.$store.getters.organization.type;
    },
    getColumnType() {
      return this.orgType === 'advertiser' ? 'Tests' : 'EPC';
    }
  },
  watch: {
    showStickyHeader: {
      immediate: true,
      handler() {
        this.$nextTick(() => this.stickyHeader());
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll, this.$supportsPassive && { passive: true });
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, this.$supportsPassive && { passive: true });
    this.handleScroll();
  },
  methods: {
    handleScroll() {
      try {
        this.showStickyHeader = this.$refs.header.getBoundingClientRect().top <= this.appHeaderHeight;
      } catch (e) {
        this.showStickyHeader = false;
      }
    },
    stickyHeader() {
      if (!this.$refs || !this.$refs.header || !this.showStickyHeader) return;

      const ref = this.$refs.sink;
      const elm = this.$refs.header;

      if (ref) {
        // Remove existing children
        while (ref.firstChild) {
          ref.removeChild(ref.firstChild);
        }

        // Append header
        if (elm) {
          const clone = elm.cloneNode(true);
          ref.appendChild(clone);
        }
      }
    }
  }
};
</script>

<style lang="scss">
.dashboard-table {
  display: flex;
  flex-direction: column;

  .dashboard-table-body {
    margin: 0;
  }

  &-row {
    &--empty {
      cursor: unset;
      pointer-events: none;

      .dashboard-table-row__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__header {
    &.dashboard-table-row {
      display: flex;
      cursor: auto;
      color: $--clb-body-font;
      font-size: $--clb-font-size-xs;
      line-height: 16px;
      font-weight: $--clb-font-weight__bold;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 $--clb-space-5 $--clb-space-4;
      margin-bottom: 0;
      border: none;

      .dashboard-table-row__cell {
        &--chart {
          justify-content: center;
        }
      }

      &:hover {
        transform: none;
        color: $--clb-body-font;
        box-shadow: none;
      }
    }
  }
}

@media (max-width: $--xs) {
  .dashboard-table {
    &__header {
      padding: $--clb-space-3;

      .dashboard-table-row__cell {
        color: $--clb-body-font;
        text-shadow: none;

        &--chart {
          display: none;
        }
      }
    }
  }
}

.app-header__portal {
  .dashboard-table {
    width: 100%;
    padding: 0 $--clb-mobile-padding;
  }
}

@media (min-width: $--sm) {
  .app-header__portal {
    .dashboard-table {
      padding: 0 $--clb-layout-4;
      margin: 0 auto;
    }
  }
}

@media (min-width: $--md) {
  .app-header__portal {
    .dashboard-table {
      padding: 0 $--clb-layout-2;
    }
  }
}

@media (min-width: $--lg) {
  .app-header__portal {
    .dashboard-table {
      padding: 0 $--clb-layout-4;
    }
  }
}
</style>
