<template>
  <div class="dashboard-table-body">
    <dashboard-table-row
      v-for="(campaign, index) in campaigns"
      :key="campaign.id"
      :show-skeletons="loading"
      :campaign="campaign"
      :graph-axis="graphAxis"
      :graph-data="graphData"
      :index="index"
      @click="$parent.$emit('campaign-click', campaign.id)" />
  </div>
</template>

<script>
import DashboardTableRow from '@/components/tables/DashboardTableRow';
export default {
  name: 'DashboardTableBody',
  components: { DashboardTableRow },
  props: {
    campaigns: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    graphAxis: {
      type: Array,
      required: true
    },
    graphData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      virtualListData: {
        items: []
      }
    };
  }
};
</script>
