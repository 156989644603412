<template>
  <div :class="campaignStatusClass" class="dashboard-table-row" @click="!showSkeletons && $emit('click')">
    <div class="dashboard-table-row__wrapper">
      <template v-if="showSkeletons">
        <div class="dashboard-table-row__cell dashboard-table-row__cell--name">
          <skeleton-box height="8px" width="50px" />
          <div class="dashboard-table-row__cell--title">
            <skeleton-box height="14px" width="50%" />
          </div>
          <skeleton-box height="12px" width="20%" />
        </div>
        <div class="hidden-xxs hidden-xs hidden-sm dashboard-table-row__cell dashboard-table-row__cell--clicks">
          <skeleton-box height="14px" width="80%" />
        </div>
        <div class="hidden-xxs dashboard-table-row__cell dashboard-table-row__cell--sales">
          <skeleton-box height="14px" width="80%" />
        </div>
        <div class="hidden-xs dashboard-table-row__cell dashboard-table-row__cell--conv-rate">
          <skeleton-box height="14px" width="80%" />
        </div>
        <div :class="`dashboard-table-row__cell--${orgDependantColumn.toLowerCase()}`" class="hidden-xs dashboard-table-row__cell">
          <skeleton-box height="14px" width="80%" />
        </div>
        <div class="dashboard-table-row__cell dashboard-table-row__cell--chart">
          <skeleton-box
            height="86px"
            width="140px"
            class="table-skew-background" />
        </div>
      </template>
      <template v-else>
        <div class="dashboard-table-row__cell dashboard-table-row__cell--name">
          <small>#{{ campaign.id }}</small>
          <div class="dashboard-table-row__cell--title">
            {{ campaign.name }}
          </div> {{ (campaign.countries || []).slice(0, 5).join(", ") || '-' }}{{ (campaign.countries || []).length > 5? ', ...': '' }}
        </div>
        <div class="dashboard-table-row__cell dashboard-table-row__cell--clicks hidden-xs hidden-sm">
          {{ campaign.formatted_values.clicks }}
        </div>
        <div class="dashboard-table-row__cell dashboard-table-row__cell--sales">
          {{ campaign.formatted_values.sales }}
        </div>
        <div :class="{ 'hidden-xs': isA2 }" class="dashboard-table-row__cell dashboard-table-row__cell--conv-rate">
          {{ campaign.formatted_values.conversion_rate }}
        </div>
        <div :class="[`dashboard-table-row__cell--${orgDependantColumn}`,{'hidden-xs': isA1}]" class="dashboard-table-row__cell">
          {{ campaign[orgDependantColumn] }}
        </div>
        <div class="dashboard-table-row__cell dashboard-table-row__cell--chart">
          <div class="dashboard-table-row__cell-skew-background" />
          <bar-chart
            :data="graphData[index]"
            :width="120"
            :height="60"
            :animate="false"
            :show-axis="false"
            :show-tooltip="false"
            :stroke-width="0"
            :fill-color="fillColor"
            :y-axis-range="graphAxis"
            stroke-color="none" />
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import SkeletonBox from '@/components/skeletons/SkeletonBox';
import BarChart from '@/components/charts/BarChart';
import Breakpoints from '@/mixins/Breakpoints';

export default {
  name: 'DashboardTableRow',
  components: {
    SkeletonBox,
    BarChart
  },
  mixins: [Breakpoints([450])],
  props: {
    userSubType: {
      type: String,
      default: ''
    },
    showSkeletons: {
      type: Boolean,
      default: false
    },
    campaign: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    graphAxis: {
      type: Array,
      required: true
    },
    graphData: {
      type: Array,
      required: true
    }
  },
  computed: {
    isA1() {
      return this.$store.getters.userSubType === 'a1';
    },
    isA2() {
      return this.$store.getters.userSubType === 'a2';
    },
    fillColor() {
      return this.windowBreakpoint >= 450 ? 'rgba(243,245,253,0.85)' : 'rgba(243,245,253,0.3)';
    },
    campaignStatusClass() {
      const status = this.campaign.status || 'skeleton';
      return `dashboard-table-row--${status}`;
    },
    orgType() {
      return this.$store.getters.organization.type;
    },
    orgDependantColumn() {
      return this.orgType === 'advertiser' ? 'tests' : 'epc';
    }
  }
};
</script>

<style lang="scss">
.dashboard-table-row {
  width: 100%;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.3s ease;
  cursor: pointer;
  border: 1px solid #ececec;
  color: #525662;
  margin: 0 0 $--clb-space-3 0;
  padding-left: $--clb-space-1;
  font-weight: 400;
  font-size: 14px;
  border-radius: $--clb-space-1;

  &__wrapper {
    height: 86px;
    padding: $--clb-space-3 $--clb-space-5 $--clb-space-3 $--clb-space-4;
    border-radius: 4px;
    background: #fff;
    display: flex;
  }

  &__cell {
    min-width: 80px;
    width: 80px;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;

    &--name {
      min-width: 200px;
      width: 100%;
      flex-grow: 1;
      display: block;
      z-index: auto;
    }

    &--title {
      font-weight: 700;
      overflow: hidden;
      min-width: 0;
      text-overflow: ellipsis;
    }

    &--chart {
      min-width: 125px;
      justify-content: flex-end;
      overflow: visible;
      z-index: auto;

      .svg-bar-cont {
        border-bottom: 1px solid rgba(243, 245, 253, 0.85);
        height: 63px;
        width: 120px;
        display: flex;
        align-items: flex-end;

        svg {
          margin-bottom: 2px;
        }
      }
    }

    &-skew-background {
      position: absolute;
      height: 86px;
      width: calc(100% + 44px);
      top: -12px;
      right: -36px;
      bottom: 0;
      transform: skew(-10deg);
      border-radius: 2px;
      background: $--clb-color-primary;
    }
  }

  .hidden-xs-to-sm {
    display: none;
  }

  &--prelaunch {
    background-color: $--clb-color-pink;
  }

  &--setup,
  &--capped {
    background-color: $--clb-color-info;
  }

  &--active {
    background-color: #81cf74;
  }

  &--paused {
    background-color: $--clb-color-warning;
  }

  &--cancelled,
  &--expired {
    background-color: $--clb-color-danger;
  }

  &--skeleton {
    background-color: $--clb-color-secondary__light;

    .skeleton-box {
      display: inline;
    }

    .dashboard-table-row__cell--chart {
      .skeleton-box {
        position: absolute;
        right: -$--clb-space-6;
      }
    }
  }

  &:hover:not(&--skeleton) {
    box-shadow: $--box-shadow-light;
    transform: translateY(-3px);
    color: $--jb-light-primary-color;
    border-color: $--jb-light-primary-color;

    .dashboard-table-row__wrapper {
      background: $--color-light;
    }

    .dashboard-table-row__cell--title {
      color: $--jb-dark-primary-color;
    }
  }
}

@media (max-width: $--sm) {
  .dashboard-table-row {
    &__cell {
      min-width: 70px;

      &--name {
        min-width: 120px;
      }

      &--chart {
        min-width: 125px;
      }

      &-skew-background {
        width: calc(100% + 35px);
        transform: skew(-10deg);
        right: -30px;
      }
    }
  }
}

@media (max-width: $--xs) {
  .dashboard-table-row {
    transition: none;

    &__wrapper {
      padding: $--clb-space-3;
    }

    &__cell {
      min-width: 60px;
      color: $--clb-color-primary__white;
      text-shadow: 0 0 3px #666;
      font-weight: 500;

      &--name {
        color: $--clb-body-font;
        min-width: 80px;
        text-shadow: none;
        font-weight: normal;
      }

      &--chart {
        min-width: 90px;
        position: absolute;
        right: 12px;
      }

      &-skew-background {
        min-width: 100px;
        width: calc(100% + 52px) !important;
      }
    }

    .svg-bar-cont {
      border-bottom: 1px solid rgba(243, 245, 253, 0.3);

      rect {
        fill: rgba(243, 245, 253, 0.3);
      }
    }

    &:hover:not(&--skeleton) {
      transform: none;
    }
  }
}
</style>
