<template>
  <section class="performance-dashboard">
    <section v-if="isAffiliate && (fetchingLaunchedCampaigns || launchedCampaigns.length)" class="performance-dashboard__latest">
      <div class="performance-dashboard__header">
        <app-heading level="h3" class="tw-mb-0">
          Newly Launched
        </app-heading>
        <router-link to="/discover" class="performance-dashboard__header-discover">
          Discover <span class="hidden-xs-and-down">Campaigns </span><i class="el-icon-arrow-right" />
        </router-link>
      </div>
      <app-glide
        :items="fetchingLaunchedCampaigns? new Array(8): launchedCampaigns"
        :loading="fetchingLaunchedCampaigns"
        class="performance-dashboard__content-promoted"
        @item-click="handleViewLaunch">
        <campaign-story-skeleton v-if="fetchingLaunchedCampaigns" slot-scope="row" :campaign="row.item" />
        <campaign-story v-else slot-scope="row" :campaign="row.item" />
      </app-glide>
    </section>

    <section v-if="!isAdmin" class="tw-mt-layout-3 tw-mb-layout-3 lg:tw-mt-layout-2 lg:tw-mb-layout-2 tw-bg-jb-purple-dark tw-flex tw-justify-between tw-overflow-hidden tw-rounded tw-cursor-pointer" :class="{ 'lg:tw-mt-layout-5': isAffiliate }" @click="$router.push({ name: 'referralMarketingLayout' });">
      <div class="sm:tw-flex-row sm:tw-items-center tw-p-space-4 sm:tw-p-7 tw-min-w-max tw-flex tw-flex-col tw-content-center tw-justify-between tw-flex-grow tw-pr-0">
        <app-text weight="semi-bold" color="white" class="sm:tw-mb-0 tw-mb-space-2 sm:tw-text-h3 tw-text-h4 tw-flex tw-content-center">
          Refer &amp; Get Paid!
        </app-text>

        <app-button
          size="micro"
          class="tw-text-jb-indigo tw-flex tw-bg-white">
          Start Referring <i class="el-icon-arrow-right" />
        </app-button>
      </div>
      <div class=" sm:tw-w-36 tw-w-24 tw-h-8">
        <referral-abstract-illustration class=" sm:tw-mr-0 tw-h-auto tw--mb-2 tw--mr-16" />
      </div>
    </section>

    <section class="performance-dashboard__overview">
      <performance-overview />
    </section>
    <section class="performance-dashboard__chart-filters">
      <date-picker-filter
        :value="dateOptions"
        :hide-date-picker-input="true"
        @input="handleDateRangeChange" />
      <campaign-export-button :disabled="firstLoad || noCsvData" :active-date-options="dateOptions" class="sm:tw-flex tw-hidden" />
    </section>
    <section class="performance-dashboard__table">
      <performance-table @campaigns-data-change="handleCampaignsDataChange">
        <dashboard-table-body
          slot-scope="{ campaigns, loading, graphAxis, graphData }"
          :loading="loading"
          :campaigns="campaigns"
          :graph-axis="graphAxis"
          :graph-data="graphData" />
      </performance-table>
    </section>
  </section>
</template>

<script>
import DatePickerFilter from '@/components/filters/DatePickerFilter';
import AppGlide from '@/components/AppGlide';
import AppText from '@/components/AppText';
import AppButton from '@/components/AppButton';
import CampaignExportButton from '@/components/campaigns/CampaignExportButton';
import CampaignStory from '@/components/campaigns/CampaignStory';
import CampaignStorySkeleton from '@/components/skeletons/campaigns/CampaignStorySkeleton';
import DashboardTableBody from '@/components/tables/DashboardTableBody';
import PerformanceOverview from '@/sections/PerformanceOverview';
import PerformanceTable from '@/sections/PerformanceTable';
import AppHeading from '@/components/AppHeading';
import dayjs from 'dayjs';
import ReferralAbstractIllustration from '@/assets/svg/referral-abstract-illustration.svg';

export default {
  name: 'PerformanceDashboard',
  components: {
    AppGlide,
    AppText,
    AppButton,
    CampaignExportButton,
    CampaignStory,
    AppHeading,
    CampaignStorySkeleton,
    DashboardTableBody,
    DatePickerFilter,
    PerformanceOverview,
    PerformanceTable,
    ReferralAbstractIllustration
  },
  data() {
    return {
      startDate: dayjs().subtract(1, 'year'),
      noCsvData: false,
      firstLoad: true,
      loadingOverview: true,
      loadingCampaigns: true,
      overviewData: null
    };
  },
  computed: {
    orgId() {
      return this.$store.getters.organization.id;
    },
    isAffiliate() {
      return this.$store.getters.userIsAffiliate;
    },
    isAdmin() {
      return this.$store.getters.userIsAdmin;
    },
    dateOptions() {
      return this.$store.getters.getMetricDateOptions;
    },
    launchedCampaigns() {
      return this.$store.getters.launchedCampaigns;
    },
    fetchingLaunchedCampaigns() {
      return this.$store.getters.fetchingLaunchedCampaigns;
    }
  },
  created() {
    if (this.isAffiliate) {
      this.fetchLaunches();
    }
  },
  methods: {
    handleViewLaunch(campaign) {
      this.$store.dispatch('setNavigationStack', {
        apiCall: this.$api.campaigns.launches,
        name: 'launches',
        payload: {
          orgId: this.orgId,
          params: { page: 1 },
          storeAction: 'fetchLaunches'
        },
        totalPages: 1,
        items: this.$store.getters.launchedCampaigns.slice(0).map(v => v.id)
      });
      this.$router.push(`/campaign/${campaign.id}`);
      scrollTo && scrollTo(0, 0);
    },
    handleCampaignsDataChange(campaignsData) {
      this.firstLoad = false;
      this.noCsvData = campaignsData.length === 0;
    },
    handleDateRangeChange(newDate) {
      this.$store.dispatch('setMetricDateRange', newDate);
    },
    fetchLaunches() {
      this.$api.campaigns.launches({
        orgId: this.orgId,
        storeAction: 'fetchLaunches'
      });
    }
  }
};
</script>

<style lang="scss">
.performance-dashboard {
  @include page-layout;

  &__header {
    @apply tw-flex tw-mb-space-4 tw-justify-between tw-items-center;

    &-discover {
      @apply tw-text-sm;
    }
  }

  &__table {
    min-height: 354px;

    @apply tw-mb-layout-2;
  }

  &__latest {
    @apply tw-flex tw-flex-col tw-mb-layout-2;
  }

  &__content-promoted {
    @apply tw-mb-layout-2 xs:tw-mb-layout-1 sm:tw-mb-0 ;

    .campaign-story {
      @apply tw-mr-space-3;
    }

    .campaign-story-skeleton {
      @apply tw-mr-space-3;
    }
  }

  &__chart-filters {
    @apply tw-my-layout-1 tw-flex tw-flex-col sm:tw-flex-row tw-justify-between sm:tw-my-layout-3 sm:tw-mx-0;

    .date-picker-filter {
      @apply tw-w-full sm:tw-max-w-sm sm:tw-m-0;

      &__tabs {
        @apply tw-bg-white;
      }
    }
  }
}
</style>
