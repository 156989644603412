<template>
  <div class="performance-overview">
    <header class="performance-overview__header">
      <div class="performance-overview__header-left">
        <h3>Performance</h3>
      </div>
      <div class="performance-overview__header-right">
        <span class="performance-overview__header-right-right-title">{{ paymentType | capitalize }}</span>
        <skeleton-box v-if="isFirstLoad" width="120px" height="36px" />
        <strong v-else v-loading="loadingOverview">{{ paid }}</strong>
      </div>
    </header>
    <section class="performance-overview__body">
      <skeleton-box
        v-if="showPerformanceMetricsVisualizationSkeleton"
        :animate="isActivePage"
        class="performance-overview__visualization-skeleton"
        width="100%" />
      <performance-metrics-visualization
        v-else
        :loading="loadingOverview"
        :overview-data="overviewData"
        :metric-key="metricKey"
        @handle-metric-change="handleMetricChange" />
    </section>
  </div>
</template>

<script>
import PerformanceMetricsVisualization from '@/components/visualizations/PerformanceMetricsVisualization';
import SkeletonBox from '@/components/skeletons/SkeletonBox';

export default {
  name: 'PerformanceOverview',
  components: {
    PerformanceMetricsVisualization,
    SkeletonBox
  },
  data() {
    return {
      loadingOverview: true,
      overviewData: null,
      isFirstLoad: true
    };
  },
  computed: {
    orgId() {
      return this.$store.getters.organization.id;
    },
    isAffiliate() {
      return this.$store.getters.userIsAffiliate;
    },
    paymentType() {
      return this.isAffiliate ? 'earned' : 'paid';
    },
    metricKey() {
      return this.$store.getters.getMetricKey;
    },
    paid() {
      return this.overviewData !== null && this.overviewData.money ? this.overviewData.money.sum : '$0.00';
    },
    dateOptions() {
      return this.$store.getters.getMetricDateOptions;
    },
    showPerformanceMetricsVisualizationSkeleton() {
      return !this.isActivePage || this.isFirstLoad;
    },
    isActivePage() {
      if (this.$deviceType === 'mobile') {
        return this.$f7.views.main.router.currentRoute.name === 'performance-dashboard';
      } else {
        return this.$route.name === 'performance-dashboard';
      }
    }
  },
  watch: {
    dateOptions() {
      this.fetchReportingOverview();
    }
  },
  created() {
    this.fetchReportingOverview();
  },
  methods: {
    handleMetricChange(newMetricKey) {
      if (newMetricKey !== this.metricKey) {
        this.$store.dispatch('setMetricKey', { metricKey: newMetricKey });
      }
    },
    fetchReportingOverview() {
      this.loadingOverview = true;
      this.$store
        .dispatch('fetchReportingOverview', {
          orgId: this.orgId,
          options: this.dateOptions
        })
        .then(response => {
          this.overviewData = Object.freeze(response);
          this.loadingOverview = this.isFirstLoad = false;
        });
    }
  }
};
</script>

<style lang="scss">
.performance-overview {
  .el-loading-mask {
    z-index: auto;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 $--clb-layout-1;
    max-height: 33px;
    min-height: 33px;

    &-left,
    &-right {
      line-height: $--clb-layout-2;
    }

    &-left {
      h3 {
        margin: 0;
        font-size: $--clb-h3__font-size;
      }
    }

    &-right {
      display: flex;
      padding-top: $--clb-space-1;
      justify-content: flex-start;
      align-items: center;
      min-width: 117px;

      &-title {
        font-size: 12px;
      }

      .skeleton-box {
        margin-left: $--clb-space-2;
      }

      strong {
        font-size: $--clb-font-size-base;
        font-weight: 500;
        padding-left: $--clb-space-2;
        color: $--clb-color__headings;
      }
    }
  }

  &__body {
    .performance-metrics-visualization {
      height: 100%;
      border-radius: $--clb-border-radius;
      padding: 0 0 $--clb-layout-2 0;
    }

    .performance-overview__visualization-skeleton {
      height: calc(#{$--clb-pmv-chart-height-xs} + #{$--clb-pmv-list-height-xs});
    }
  }
}

@media (min-width: $--xs) {
  .performance-overview {
    &__header {
      &-right {
        padding-top: $--clb-space-1;
        justify-content: flex-start;
      }

      strong {
        font-size: $--clb-h3__font-size;
        font-weight: 700;
      }
    }

    &__body {
      .performance-overview__visualization-skeleton {
        height: calc(#{$--clb-pmv-chart-height-xs} + #{$--clb-pmv-list-height-sm});
      }
    }
  }
}

@media (min-width: $--sm) {
  .performance-overview {
    &__header {
      &-right {
        padding-top: $--clb-space-1;
        justify-content: flex-end;
      }
    }

    &__body {
      .performance-metrics-visualization {
        padding: $--clb-layout-2;
      }

      .performance-overview__visualization-skeleton {
        height: 360px;
      }
    }
  }
}
</style>
